import React, { Suspense } from 'react';
import ErrorBoundaryWithLogger from 'common/ErrorBoundary/ErrorBoundary';
import lazyWithRetries from '../../middleware/lazyWithRetries';

const FooterPL = lazyWithRetries(
  () => import(/* webpackChunkName: "FooterPL" */ '../../components/templates/FooterPL/FooterPL'),
);

const FundSiteFooter = lazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "FundSiteFooter" */ '../../components/templates/FundSiteFooter/FundSiteFooter'
    ),
);

const Footer = lazyWithRetries(
  () => import(/* webpackChunkName: "Footer" */ '../../components/templates/Footer/Footer'),
);

type Props = {
  storeIdentifierName: string;
};

/*
  FooterRouter handles loading the correct Footer template based on the storeIdentifierName.
  Logic or components that are shared among the Footer templates should live here.
*/

const FooterRouter = ({ storeIdentifierName }: Props) => {
  const footerSelector = () => {
    switch (storeIdentifierName) {
      case 'plf':
      case 'sm':
        return <FundSiteFooter />;
      case 'pl':
      case 'mj':
        return (
          <Suspense>
            <FooterPL />
          </Suspense>
        );
      default:
        return (
          <Suspense>
            <Footer />
          </Suspense>
        );
    }
  };

  return (
    <ErrorBoundaryWithLogger>
      <Suspense>{footerSelector()}</Suspense>
    </ErrorBoundaryWithLogger>
  );
};

export default FooterRouter;
