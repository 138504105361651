import React, { Suspense } from 'react';
import lazyWithRetries from '../../middleware/lazyWithRetries';
import Toasty from '../../components/common/Toasty/Toasty';
import OnPageLoadLocationHandler from '../../components/common/LocationServices/OnPageLoadLocationHandler/OnPageLoadLocationHandler';
import StoreSelectionModal from '../../components/common/LocationServices/StoreSelectionModal/StoreSelectionModal';
import SameDayDeliveryModal from '../../components/common/LocationServices/SameDayDeliveryModal/SameDayDeliveryModal';
import ErrorBoundaryWithLogger from '../../components/common/ErrorBoundary/ErrorBoundary';

const Quickview = lazyWithRetries(
  () => import(/* webpackChunkName: "Quickview" */ '../../components/Quickview/Quickview'),
);

const Header = lazyWithRetries(
  () => import(/* webpackChunkName: "Header" */ '../../components/templates/Header/Header'),
);

const MicrositeHeader = lazyWithRetries(
  () =>
    import(
      /* webpackChunkName: "MicrositeHeader" */ '../../components/templates/MicrositeHeader/MicrositeHeader'
    ),
);

const HeaderSM = lazyWithRetries(
  () => import(/* webpackChunkName: "HeaderSM" */ '../../components/templates/HeaderSM/HeaderSM'),
);

const HeaderPLF = lazyWithRetries(
  () =>
    import(/* webpackChunkName: "HeaderPLF" */ '../../components/templates/HeaderPLF/HeaderPLF'),
);

const ListsPanel = lazyWithRetries(
  () => import(/* webpackChunkName: "ListsPanel" */ '../../components/ListsPanel/ListsPanel'),
);

type Props = {
  storeIdentifierName: string;
};

/*
  HeaderRouter handles loading the correct Header template based on the storeIdentifierName.
  Logic or components that are shared among the Header templates should live here.
*/

const HeaderRouter = ({ storeIdentifierName }: Props) => {
  const headerSelector = () => {
    switch (storeIdentifierName) {
      case 'plf':
        return <HeaderPLF />;
      case 'sm':
        return <HeaderSM />;
      case 'pl':
      case 'dsg':
      case 'gg':
      case 'mj':
        return <Header />;
      default:
        return <MicrositeHeader />;
    }
  };

  return (
    <>
      <ErrorBoundaryWithLogger>
        <Suspense>{headerSelector()}</Suspense>
      </ErrorBoundaryWithLogger>
      {!['plf', 'sm'].includes(storeIdentifierName) && (
        <>
          {/* Non-Lazy Loaded Components */}
          <ErrorBoundaryWithLogger>
            <OnPageLoadLocationHandler />
          </ErrorBoundaryWithLogger>
          <ErrorBoundaryWithLogger>
            <StoreSelectionModal />
          </ErrorBoundaryWithLogger>
          {/* Lazy Loaded Components */}
          <ErrorBoundaryWithLogger>
            <Suspense>
              <Quickview />
            </Suspense>
          </ErrorBoundaryWithLogger>
          <ErrorBoundaryWithLogger>
            <Suspense>
              <SameDayDeliveryModal />
            </Suspense>
          </ErrorBoundaryWithLogger>
          <ErrorBoundaryWithLogger>
            <Suspense>
              <ListsPanel />
            </Suspense>
          </ErrorBoundaryWithLogger>
          <ErrorBoundaryWithLogger>
            <Suspense>
              <Toasty />
            </Suspense>
          </ErrorBoundaryWithLogger>
        </>
      )}
    </>
  );
};

export default HeaderRouter;
