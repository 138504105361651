/* webpackChunkName: "HeaderFooter" */
import React, { startTransition, useEffect } from 'react';
import useClientSideRender from '../../hooks/useClientSideRender';
import {
  breakpointSelector,
  storeIdentifierNameSelector,
} from '../../store/selectors/commonSelector';
import { getViewPortBreakpoint } from '../../utility/common-utils';
import { setFooterTaxo, setHeaderTaxo } from '../../store/slices/taxonomy';
import { useAppDispatch, useAppSelector } from '../../store/store';

import FooterRouter from '../footer/FooterRouter';
import HeaderRouter from '../header/HeaderRouter';
import { StoreIdentifierName } from '../../types';
import { setBreakpoint } from '../../store/slices/common';
import useHomr from '../../hooks/useHomr';
import useWindowVariables from '../../hooks/useWindowVariables';

type Props = {
  route: 'header' | 'footer';
};

/*
  HeaderFooter is a shared entry point component prior to routing to Header or Footer component templates.
  This component should be used to put logic or components that is shared/used for all Header & Footer templates.
*/

const HeaderFooter = ({ route }: Props) => {
  const dispatch = useAppDispatch();
  const storeIdentifierName = useAppSelector(storeIdentifierNameSelector);
  const viewPortBreakpoint = useAppSelector(breakpointSelector);
  useWindowVariables();
  const { isClient } = useClientSideRender();

  useHomr(
    route,
    'default',
    storeIdentifierName as StoreIdentifierName,
    route === 'footer' ? setFooterTaxo : setHeaderTaxo,
  );

  const updateViewPortBreakpoint = () => {
    const breakpointValue = getViewPortBreakpoint();
    if (viewPortBreakpoint !== breakpointValue) {
      startTransition(() => {
        dispatch(setBreakpoint(breakpointValue));
      });
    }
  };

  useEffect(() => {
    // Handles listening for browser resizes and updating our global breakpoint value for dynamic rendering
    if (isClient) {
      updateViewPortBreakpoint();
    }
    window.addEventListener('resize', updateViewPortBreakpoint);
    return () => {
      window.removeEventListener('resize', updateViewPortBreakpoint);
    };
  }, [isClient, viewPortBreakpoint]);

  return (
    <>
      {route === 'footer' ? (
        <FooterRouter key="footer" storeIdentifierName={storeIdentifierName} />
      ) : (
        <HeaderRouter key="header" storeIdentifierName={storeIdentifierName} />
      )}
    </>
  );
};

export default HeaderFooter;
