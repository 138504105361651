import { Provider } from 'react-redux';
import React, { StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import HeaderFooter from '../header-footer/HeaderFooter';
import { CommunicationSection, CsvData, FooterLink, ReduxState } from '../../types';
import { setCommonState } from '../../store/slices/common';
import { setFooterTaxo } from '../../store/slices/taxonomy';
import { store } from '../../store/store';

declare global {
  export interface Window {
    footerData: {
      csvData: CsvData[];
      communicationSection?: CommunicationSection;
      footerLinks?: FooterLink[];
      searchApi?: string;
      thumbnailApi?: string;
      tickerApi?: string;
      storeIdentifierName: string;
    };
    footerReduxData: ReduxState;
    cartCountComponent?: {
      setCartCount: (value: string | number) => void;
    };
  }
}

store.dispatch(setFooterTaxo(window.footerReduxData.taxonomy.footer));
store.dispatch(setCommonState(window.footerReduxData.common));

hydrateRoot(
  document.getElementById('responsive-footer') as HTMLElement,
  <StrictMode>
    <Provider store={store}>
      <HeaderFooter route={window.footerReduxData.common.headerFooterType} />
    </Provider>
  </StrictMode>,
);
